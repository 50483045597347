import React from "react";
import styled from "styled-components";
import Header from "../components/header";
import backgroundVideo from "../assets/decv.mp4";
import img1 from "../assets/2 1.png";
import img2 from "../assets/3 1.png";
import img3 from "../assets/4 1.png";
import img4 from "../assets/5 1.png";
import Footer from "../components/footer";
import LogoWhite from "../assets/Decentfi.png";

const VideoBackground = styled.video`
  width: 100%;
  height: 70vh;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  z-index: -1000;
`;

const MainHero = styled.div`
  position: absolute;
  top: 25%;
  align-items: left;
  width: 700px;
  transform: translate(20%, 0%);
  text-align: left;
  color: white;

  @media (max-width: 768px) {
    width: 90%;
    transform: translate(5%, 0%);
    text-align: center;
  }
`;

const HeroTitle = styled.h1`
  font-size: 0.8em;

  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;

const DescHero = styled.p`
  font-size: 1.3em;
  margin: 1em 0;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const ButtonHero = styled.div`
  display: flex;
  justify-content: left;
  gap: 1em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap:0;
  }
`;

const SecondSection = styled.div`
  text-align: center;
  background: linear-gradient(to right, #142759, #868a91);
  transition: background 0.5s ease-in-out;
  margin-top: -4px;

  @media (max-width: 768px) {
    transform: translate(0%, 0%);
    padding: 1em 0;
    margin-top: -6px;
  }
`;

const SecondTitleContainer = styled.div`
  position: relative;
  top: 50px;
`;

const SecondTitle = styled.div`
  font-size: 3em;
  color: #ffff;
  position: relative;
  z-index: 1000;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const StyledParagraph = styled.p`
  font-size: 1.5em;
  color: #ffffff;
  margin: 1em 0;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const RelativeLogos = styled.img`
  width: 300px;
  position: absolute;
  height: auto;
  margin: 0 auto;
  transform: translate(80%, -43%);
  z-index: 100;

  @media (max-width: 768px) {
    transform: translate(0%, -59%);
    width: 100px;
  }
`;

const GridProjectGoals = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2em;
  margin-top: 120px;
  padding: 2em 2em 0em 2em;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GoalItem = styled.div`
  text-align: center;
  padding: 1em;
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const GoalImage = styled.img`
  width: 100px;
  height: auto;
  margin: 0 auto;
`;

const Content3 = styled.div`
  background: #142759;
  color: white;
  padding: 2em;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1em;
  }
`;

const DefiLogos = styled.img`
  width: 300px;
  height: auto;
  margin: 0;
  transform: translate(150%, 56%);
  align-items: left;
  display: flex;

  @media (max-width: 768px) {
    transform: translate(0%, 59%);
    width: 100px;
  }
`;

const Content3Section = styled.div`
  margin: 2em 0;

  @media (max-width: 768px) {
    margin: 1em 0;
  }
`;

const H3 = styled.h3`
  font-size: 2em;
  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const P = styled.p`
  font-size: 1.5em;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1em;
    max-width: 90%;
  }
`;

const ButtonLink = styled.a`
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5em 2em;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  @media (max-width: 768px) {
    width: 50%;
    text-align: center;
    margin-bottom: 1em;
  }
`;

function Index() {
  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <VideoBackground autoPlay muted loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoBackground>
        <MainHero>
          <HeroTitle>
            <h1>𝘿𝙀𝘾𝙀𝙉𝙏𝙁𝙄 𝙥𝙤𝙬𝙚𝙧𝙚𝙙 𝙗𝙮 𝘼𝙄𝙉𝙊𝘿𝙀'𝙨 𝙖𝙧𝙩𝙞𝙛𝙞𝙘𝙞𝙖𝙡 𝙞𝙣𝙩𝙚𝙡𝙡𝙞𝙜𝙚𝙣𝙘𝙚 (𝘼𝙄)</h1>
          </HeroTitle>
          <DescHero>
            DECENTFI is a decentralized finance (DeFi) platform that leverages artificial intelligence (AI) technology to enhance user experience in the blockchain and cryptocurrency industries.
          </DescHero>
          <ButtonHero>
            <ButtonLink href="https://defi-ai.gitbook.io/decentfi/">Read Docs</ButtonLink>
            <ButtonLink href="/">Platform</ButtonLink>
          </ButtonHero>
        </MainHero>
      </div>

      <SecondSection>
        <RelativeLogos src={LogoWhite} alt="Logo" />
        <SecondTitleContainer>
          <SecondTitle>DECENTFI Project Goals</SecondTitle>
          <StyledParagraph>Build a DeFi protocol which leverages artificial intelligence</StyledParagraph>
        </SecondTitleContainer>
        <GridProjectGoals>
          <GoalItem>
            <GoalImage src={img1} alt="Goal 1" />
            <h3>INTEGRATE DEFI PROTOCOL</h3>
            <p>
              DECENTFI will select and integrate proven core DeFi protocol,
              providing a solid foundation for the broader ecosystem.
            </p>
          </GoalItem>
          <GoalItem>
            <GoalImage src={img2} alt="Goal 2" />
            <h3>ARTIFICIAL INTELLIGENCE MODULE</h3>
            <p>
              DECENTFI will include Artificial Intelligence modules for market
              sentiment analysis, automated portfolio management, smart lending
              with AI, and prediction of Agriculture production.
            </p>
          </GoalItem>
          <GoalItem>
            <GoalImage src={img3} alt="Goal 3" />
            <h3>THE PREDICTIVE YIELD FARMING MARKET</h3>
            <p>
              Integrating a yield farming prediction market that leverages
              sentiment analysis and AI predictions to provide guidance to
              yield farmers.
            </p>
          </GoalItem>
          <GoalItem>
            <GoalImage src={img4} alt="Goal 4" />
            <h3>PREDICTED DEFI GOVERNANCE</h3>
            <p>
              Implement a governance system supported by AI predictions to
              give token holders a more informed and effective voice.
            </p>
          </GoalItem>
        </GridProjectGoals>
        <DefiLogos src={LogoWhite} alt="Logo" />
        <Content3>
          <Content3Section>
            <H3>Defi Platform</H3>
            <P>
              The DecenFI Platform offers a variety of decentralized financial
              services, including lending, exchange, staking, and more, with the
              integration of AI technology to increase efficiency and security.
            </P>
          </Content3Section>
          <Content3Section>
            <H3>Real Business Assets</H3>
            <P>
              Real Business Assets by Adopting the DePIN System: DecenFI also
              introduces real business assets by adopting the DePIN system. This
              allows users to access investments in real assets in a decentralized
              and secure manner.
            </P>
          </Content3Section>
          <Content3Section>
            <H3>Adoption</H3>
            <P>
              DecenFI will adopt the DePIN ecosystem for the utility of Real
              Business Assets. The category used PRN (Physical Resource Network).
            </P>
          </Content3Section>
        </Content3>
        <Footer />
      </SecondSection>
    </>
  );
}

export default Index;

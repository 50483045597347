import { useState, useEffect } from "react";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import "./style.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [bgColor, setBgColor] = useState("transparent");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      setBgColor("rgba(0, 0, 0, 0.8)");
    } else {
      setBgColor("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "Platform", path: "/platform" },
    { name: "Docs", path: "https://defi-ai.gitbook.io/decentfi/" },
    { name: "Medium", path: "https://medium.com/@DECENTFI_DECFI" },
    { name: "Community", path: "https://t.me/DECENTFIChannel" },
  ];

  return (
    <div className="contHeader" style={{ backgroundColor: bgColor }}>
      <Link to="/">
        <img className="logo" src={Logo} alt="Linx Logo" />
      </Link>
      <div className="mainContentHeader">
        {navLinks.map((item) =>
          item.path.startsWith("http") ? (
            <a
              href={item.path}
              key={item.name}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4 style={{ color: "white" }}>{item.name}</h4>
            </a>
          ) : (
            <Link
              to={item.path}
              key={item.name}
              style={{ textDecoration: "none" }}
            >
              <h4 style={{ color: "white" }}>{item.name}</h4>
            </Link>
          )
        )}
      </div>
      <div className={`contentHeader ${isOpen ? "open" : ""}`}>
        {navLinks.map((item) =>
          item.path.startsWith("http") ? (
            <a
              href={item.path}
              key={item.name}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>{item.name}</h4>
            </a>
          ) : (
            <Link
              to={item.path}
              key={item.name}
              style={{ textDecoration: "none" }}
            >
              <h4>{item.name}</h4>
            </Link>
          )
        )}
      </div>
      <div className="menuToggle" onClick={toggleMenu}>
        <div className={`hamburger ${isOpen ? "open" : ""}`}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
    </div>
  );
}

export default Header;

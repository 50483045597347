import React from "react";
import { FaTwitter, FaTelegramPlane, FaMedium, FaRobot } from "react-icons/fa";
import LogoBlue from "../../assets/Logo (2).png";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  @media (max-width: 768px) {
    width: 100px;
  }
`;

const PrivacyButton = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Copyright = styled.p`
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
`;

const SocialList = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1em;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: #007BFF;
  }
`;

const DescInfoFooter = styled.p`
  margin-top: 20px;
  font-size: 0.9em;
  color: #6c757d;
  max-width: 400px;
  @media (max-width: 768px) {
    margin-top: 10px;
    max-width: 90%;
  }
`;

function Index() {
  return (
    <Container>
      <div>
        <Logo src={LogoBlue} alt="Logo" />
        <PrivacyButton>
          <p>Privacy Policy</p>
          <p>Term Of Use</p>
        </PrivacyButton>
        <Copyright>Copyright @2024 DECENTFI.</Copyright>
      </div>
      <div>
        <SocialList>
          <SocialLink href="https://x.com/Decentfi_Tech" target="_blank" rel="noopener noreferrer">
            <FaTwitter /> Twitter
          </SocialLink>
          <SocialLink href="https://t.me/DECENTFIChannel" target="_blank" rel="noopener noreferrer">
            <FaTelegramPlane /> Telegram
          </SocialLink>
          <SocialLink href="https://medium.com/@DECENTFI_DECFI" target="_blank" rel="noopener noreferrer">
            <FaMedium /> Medium
          </SocialLink>
          <SocialLink href="/" target="_blank" rel="noopener noreferrer">
            <FaRobot /> Bot
          </SocialLink>
        </SocialList>
        <DescInfoFooter>
          THE INFORMATION CONTAINED ON THIS WEBSITE IS FOR INFORMATIONAL
          PURPOSES ONLY. THE INFORMATION HEREIN DOES NOT CONSTITUTE OR SHALL BE
          PART OF AN OFFER TO SELL OR SOLICITATION OF AN OFFER TO BUY SECURITIES
          OR A RECOMMENDATION TO INVEST IN ANY PROJECT.
        </DescInfoFooter>
      </div>
    </Container>
  );
}

export default Index;
